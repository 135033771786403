// axios
import axios from 'axios'
import router from '@/router'
import Vue from 'vue'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    // baseURL: 'http://localhost:9999',
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
    config => {
        // Do something before request is sent

        const accessToken = localStorage.getItem('token')
        // const accessToken = "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1biI6ImRyaXZlcjAwMSIsIm5uIjoiZHJpdmVyMDAxIiwiY2xpIjoiQVBQX1VTRVIiLCJpc3MiOiJhbnhwcC5jb20iLCJzdWIiOiIyOGEwOGY1ODQ5NmYxMWVlYjk1ZjAyNDJhYzExMDAwNCIsImV4cCI6MTY5NjUxNjIwNiwibmJmIjoxNjkzOTI0MjA2LCJpYXQiOjE2OTM5MjQyMDYsImp0aSI6IjkyNjk2OTE2ZTRmMTRjZDE5NzNkMTgwOGMwZjQyOTllIn0.3CGZfhNuia3qkGM96vFQBTLlmzNu0UHgL_POwo32o1I1B1EP3H87_TOSRC2piwHLcctjgCU2e6u76FhbAnj7DQ"

        // eslint-disable-next-line no-param-reassign
        if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

        return config
    },
    error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
    res => { // 状态码 2xx
        // console.log('interceptors.response', res)
        // Vue.prototype.$noty.info('test')
        return res
    },
    (error) => {
        console.log('##############', error)
        if (error.response && error.response.status === 401 && router.currentRoute.path !== '/login') {
            console.log('##############', 'Unauthorized, need login...')
            Vue.prototype.$noty.error('登录已失效')
            localStorage.removeItem('accessToken')
            router.push('/login').then()
        }
        return Promise.reject(error)
    },
)

Vue.prototype.$http = axiosIns

export default axiosIns
